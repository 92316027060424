import("imagesloaded");
import("smooth-scroll");

require("jquery-ui")
//require("material-style/plugins.min")
var SmoothScroll = require("smooth-scroll")
//var WOW = require("wow")
var imagesLoaded = require('imagesloaded');

//require("material-style/wow")
//require("material-style/imagesloaded")
require("material-style/masonry")
require("material-style/material")
require("material-style/ripples")
require("material-style/tabs")

require("material-style/app")